@import "./variables.scss";

.skills {
  background: #151515;
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $about-break) {
    height: 80vh;
  }

  .skills-content {
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: fit-content;

    @media screen and (min-width: $about-break) {
      width: 80%;
    }
    @media screen and (min-width: $tablet-max) {
      width: 70%;
    }

    h4 {
      font-family: "Roboto Mono Medium", sans-serif;
      font-variant: small-caps;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2em;
      align-self: flex-start;
      color: $purple-accent;
      margin: 0px;

      @media screen and (min-width: $about-break) {
        font-size: 22px;
      }
    }

    .skills-lists {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (min-width: $about-break) {
        width: 100%;
      }

      @media screen and (min-width: $tablet-max) {
        width: 90%;
        align-self: center;
      }
      @media screen and (min-width: $desktop) {
        width: 100%;
        margin-top: 30px;
        justify-content: space-around;
        align-items: flex-start;
      }

      .list {
        cursor: default;
        h5 {
          font-family: "Roboto Mono Regular";
          font-variant: small-caps;
          color: white;
          font-size: 16px;
          font-weight: 400;
          margin: 0px;
          margin-bottom: 25px;

          @media screen and (min-width: $about-break) {
            font-size: 20px;
          }
        }

        ul {
          list-style: none;
          margin: 0px;
          padding: 0px;
          line-height: 22px;
          width: fit-content;
          @media screen and (min-width: $about-break) {
            line-height: 25px;
          }
          li {
            font-size: 15px;
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            &:before {
              content: "›";
              color: $green-accent3;
              padding-right: 5px;

              @media screen and (min-width: $about-break) {
                padding-right: 15px;
              }
            }

            @media screen and (min-width: $about-break) {
              font-size: 18px;
            }

            @media (hover: hover) {
              &:hover {
                color: $green-accent-hover;
              }
            }
          }
        }

        &:first-of-type {
          width: 160px;
          @media screen and (min-width: $about-break) {
            width: 55%;
          }
          @media screen and (min-width: $desktop) {
            width: auto;
            margin-top: 0px;
          }
        }

        &:nth-of-type(3) {
          width: 160px;
          margin-top: 25px;
          @media screen and (min-width: $about-break) {
            width: 55%;
            margin-top: 40px;
          }
          @media screen and (min-width: $desktop) {
            width: auto;
            margin-top: 0px;
          }
        }
        &:last-of-type {
          width: 140px;
          margin-top: 25px;
          @media screen and (min-width: $about-break) {
            width: auto;
            margin-top: 40px;
          }
          @media screen and (min-width: $desktop) {
            width: auto;
            margin-top: 0px;
          }
        }

        @media screen and (min-width: $about-break) {
          // width: 50%;
        }
      }
    }
  }
}
