@import "./variables.scss";
@import url("https://use.typekit.net/huy2pfg.css");

nav {
  display: flex;
  flex-direction: row;
  height: 95px;
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba($bg, 0.95);
  transition: bottom 0.8s;
  bottom: -100px;
  align-items: center;
  justify-content: center;

  .mobile-nav {
    @media screen and (min-width: $tablet-max) {
      display: none;
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: $green-accent;

    .mobile-logo {
      width: 30px;
      height: 30px;
      stroke: $green-accent;
      path {
        &:first-of-type {
          fill: $green-accent;
        }
      }

      rect {
        fill: $green-accent;
      }
    }

    .item {
      width: 35px;
      transition: opacity 1s ease;
      // transform: translate(0);
      // opacity: 1;
      transition: opacity 1s, transform 1s;

      &.hide {
        display: none;
        transform: scale(0);
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }

      &.show {
        display: block;
        transform: scale(1);
        opacity: 1;
        animation: anim 0.3s ease-in-out;
      }

      @keyframes anim {
        0% {
          display: none;
          opacity: 0;
        }
        1% {
          display: block;
          opacity: 0;
          transform: scale(0);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }

      p {
        font-size: 10px;
        margin: 0px;
      }
    }
    .name {
      font-family: aw-conqueror-inline, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      color: white;
    }
  }

  @media screen and (min-width: $tablet-min) {
    background-color: rgba($bg, 0.85);
    justify-content: flex-end;
    transition: top 0.8s;
    top: 0;
  }

  .logo {
    width: 50px;
    height: auto;
    position: absolute;
    left: 3%;
    display: none;

    circle {
      transition: fill 0.3s ease;
      fill: #151515;
    }

    path {
      transition: fill 0.3s ease;
      &:first-of-type {
        //IP color
        fill: white;
      }
    }

    @media screen and (min-width: $tablet-max) {
      &:hover {
        circle {
          fill: white;
        }

        path {
          &:first-of-type {
            //IP color
            fill: #151515;
          }
        }
      }

      // rect {
      //   fill: $purple-accent;
      // }
    }

    @media screen and (min-width: $tablet-min) {
      display: block;
    }
  }

  .menu {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    width: 30%;
    // margin-right: 26px;
    color: white;
    font-family: "Roboto Mono", sans-serif;
    font-weight: 300;
    cursor: default;

    // @media screen and (min-width: 426px) {
    //   width: 550px;
    //   font-size: 18px;
    // }

    a {
      position: relative;
      &:hover {
        color: $green-accent;

        &::before {
          content: "{";
          position: absolute;
          left: -20px;
        }

        &::after {
          content: "}";
          position: absolute;
          right: -20px;
        }
      }
    }

    div {
      a {
        margin: 0px;
      }
    }

    @media screen and (min-width: $tablet-min) {
      display: flex;
    }
  }
}
