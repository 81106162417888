@import "./variables.scss";
@import url("https://use.typekit.net/huy2pfg.css");

.home {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: $tablet-min) {
    justify-content: end;
  }

  @media screen and (min-width: $desktop) {
    justify-content: flex-start;
  }

  div {
    align-self: center;
    text-align: left;
    background-color: #15151588;

    @media screen and (min-width: $tablet-min) {
      margin-top: 55vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (min-width: $laptop-min) {
      align-self: flex-start;
      margin-left: 10vw;
    }

    @media screen and (min-width: $desktop) {
      // margin-top: 60vh;
    }

    p {
      font-family: "Roboto Mono", serif;
      font-weight: lighter;
      font-size: 25px;
      color: #fff;
      margin: 0px;

      @media screen and (min-width: $tablet-min) {
        // display: none;
      }
    }

    h1 {
      font-size: 50px;
      letter-spacing: 0.15em;
      color: $purple-accent;
      font-family: "Roboto", serif;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      visibility: hidden;

      //conqueror font
      font-family: aw-conqueror-inline, sans-serif;
      font-weight: 400;
      font-style: normal;

      @media screen and (min-width: $tablet-min) {
        // font-size: 80px;
        color: $main-purple;
        margin: 0px;
        line-height: 70px;
        text-align: left;
        font-size: 13vw;
      }

      @media screen and (min-width: $home-break) {
        font-size: 80px;
      }

      @media screen and (min-width: $laptop-min) {
        font-size: 100px;
      }
      @media screen and (min-width: $desktop) {
        font-size: 130px;
        line-height: 130px;
      }
    }

    h2 {
      font-size: 16px;
      font-family: "Roboto Slab", serif;
      font-weight: 300;
      /* font-variant: small-caps; */
      // color: #fff;
      // color: #949495;
      color: white;
      margin: 0px;
      line-height: 25px;
      background-color: #15151575;
      font-family: "Roboto Mono", monospace;
      text-align: center;
      @media screen and (min-width: $tablet-min) {
        // font-size: 20px;
        font-weight: 300;
        text-align: left;
        font-size: 2.7vw;
        margin-top: 0px;
      }

      @media screen and (min-width: $home-break) {
        margin-top: 2vw;
        // margin-top: 20px;
      }

      @media screen and (min-width: $tablet-max) {
        // font-size: 25px;
      }

      @media screen and (min-width: $laptop-min) {
        margin-top: 30px;
        font-size: 2.1vw;
      }

      @media screen and (min-width: $desktop) {
        font-size: 30px;
        margin-top: 10px;
      }
    }
  }

  a {
    padding-top: 60px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    transform: translate(0, -50%);
    color: white;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;

    &:hover {
      // opacity: 0.5;
      // color: red;

      span {
        background-color: white;
        color: black;

        &::after {
          border-bottom: 1px solid black;
          border-left: 1px solid black;
        }
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 46px;
      height: 46px;
      margin-left: -23px;
      border: 1px solid white;
      border-radius: 100%;
      box-sizing: border-box;
      transition: background-color 0.3s, border 0.3s;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        width: 16px;
        height: 16px;
        margin: -12px 0 0 -8px;
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        transform: rotate(-45deg);
        box-sizing: border-box;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
        width: 44px;
        height: 44px;
        box-shadow: 0 0 0 0 rgba(white, 0.1);
        border-radius: 100%;
        opacity: 0;
        animation: sdb03 3s infinite;
        box-sizing: border-box;
      }

      @keyframes sdb03 {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        60% {
          box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
}
