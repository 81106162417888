@import "./variables.scss";
@import "./fonts.scss";

$animate: all 0.2s ease-in-out;

.about {
  background-color: #202022;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 0px;

  @media screen and (min-width: $tablet-min) {
    height: 100vh;
    padding: 0px;
  }

  .about-content {
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (min-width: $tablet-min) {
      width: 80%;
    }

    @media screen and (min-width: $tablet-max) {
      width: 70%;
    }

    .about-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: $tablet-min) {
        flex-direction: column;
      }

      @media screen and (min-width: $laptop-min) {
        flex-direction: row;
      }

      .all-copy {
        max-width: none;

        @media screen and (min-width: $tablet-min) {
          max-width: none;
        }
        @media screen and (min-width: $laptop-min) {
          max-width: 550px;
        }
      }
    }

    h4 {
      font-family: "Roboto Mono Medium", sans-serif;
      font-variant: small-caps;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2em;
      align-self: flex-start;
      color: $purple-accent;
      margin: 0px;
      margin-bottom: 24px;

      @media screen and (min-width: $tablet-min) {
        width: 80%;
      }

      @media screen and (min-width: $tablet-max) {
        font-size: 22px;
      }
    }

    p {
      margin: 0px;
      color: $pale-gray;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      width: 100%;
      line-height: 26px;
      margin-bottom: 20px;

      span {
        color: white;
        font-weight: 500;

        &.link {
          cursor: auto;

          &:hover {
            cursor: pointer;
            a {
              &:before,
              &:after {
                width: 100%;
                opacity: 1;
              }
            }
          }
        }
        a {
          color: white;
          text-decoration: none;
          transition: 0.3s color;

          text-align: center;
          margin: 0 auto;
          padding: 0;
          transition: $animate;
          position: relative;
          &:before,
          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0px;
            height: 1px;
            margin: 0;
            transition: $animate;
            transition-duration: 0.3s;
            opacity: 0;
            background-color: $green-accent;
          }
        }
      }

      @media screen and (min-width: $tablet-min) {
        margin-bottom: 25px;
      }

      @media screen and (min-width: $tablet-min) {
        line-height: 25px;
      }
    }

    img {
      width: 250px;
      height: auto;
      margin-top: 30px;
      align-self: center;

      @media screen and (min-width: $tablet-min) {
        width: 300px;
        height: auto;
        border-radius: 50%;
        align-self: flex-start;
        margin: 0px;
        margin-left: auto;
        transition: border-radius 1s ease;
        filter: grayscale(30%);

        margin: 0 auto;

        &:hover {
          border-radius: 5%;
          filter: grayscale(0%);
        }
      }

      @media screen and (min-width: $laptop-min) {
        margin-left: auto;
        align-self: flex-start;
      }
    }
  }
}
