@import "./variables.scss";

.client-work {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #202022;
  align-items: center;
  justify-content: center;
  padding: 140px 0px;

  .client-header {
    width: 300px;
    text-align: left;

    @media screen and (min-width: $about-break) {
      width: 80%;
    }

    h4 {
      font-family: "Roboto Mono Medium", serif;
      font-variant: small-caps;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2em;
      align-self: flex-start;
      color: $purple-accent;
      margin: 0px;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      color: white;
      margin: 0px;
    }
  }

  .all-projects {
    width: 100%;
    overflow: hidden;

    .project {
      display: flex;
      flex-direction: row;
      min-height: 260px;
      margin-top: 60px;
      position: relative;

      @media screen and (min-width: $about-break) {
        margin-top: 80px;
        height: 300px;
        background: linear-gradient(
          90deg,
          rgba(46, 46, 49, 0) 0%,
          #2e2e31 51.56%,
          rgba(46, 46, 49, 0) 100%
        );
        align-items: center;

        &:hover {
          img {
            filter: grayscale(0);
            opacity: 1;
            transform: scale(1.05);
          }
        }
      }

      &:not(:first-of-type) {
        margin-top: 80px;
      }

      .project-info {
        text-align: left;
        margin-left: 20px;
        z-index: 10;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $about-break) {
          margin-left: 70px;
          width: 80%;
          margin: 0 auto;
        }

        @media screen and (min-width: $tablet-max) {
          width: 70%;
          max-width: 640px;
        }
        @media screen and (min-width: $laptop-min) {
          width: 915px;
          max-width: none;
        }

        h5 {
          font-family: "Roboto", sans-serif;
          font-weight: normal;
          font-size: 20px;
          color: white;
          margin: 0px;
          // margin-top: 20px;
          line-height: 20px;

          .external {
            padding-left: 20px;
          }

          @media screen and (min-width: $laptop-min) {
            font-size: 22px;
          }
        }
        p {
          font-size: 17px;
          font-family: "Roboto", sans-serif;
          font-weight: 200;
          color: white;
          width: 177px;
          margin: 0px;
          margin-top: 18px;

          @media screen and (min-width: $tablet-max) {
            width: 30vw;
            min-width: 177px;
            max-width: 290px;
          }

          @media screen and (min-width: $laptop-min) {
            font-size: 20px;
          }
        }

        .external-mobile {
          margin-top: 10px;
        }

        .tag-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 25px;

          .tag {
            background-color: #151515;
            @media screen and (min-width: $tablet-max) {
              background-color: transparent;

              &:hover {
                background-color: rgba($green-accent, 0.3);
              }
            }
          }
        }

        .icons {
          margin-top: 35px;

          .external {
            margin-left: 0px;
          }
        }
      }

      img {
        position: absolute;
        left: 50%;
        z-index: 1;
        filter: grayscale(60%);
        opacity: 0.7;
        transition: all 0.3s ease;

        @media screen and (min-width: $about-break) {
          left: 45%;
        }
      }

      &.right {
        justify-content: flex-end;
        .project-info {
          text-align: right;
          margin-right: 20px;
          margin-left: 0px;
          align-items: flex-end;

          @media screen and (min-width: $about-break) {
            margin-right: 70px;
            width: 80%;
            margin: 0 auto;
          }

          @media screen and (min-width: $tablet-max) {
            width: 70%;
            max-width: 640px;
          }

          @media screen and (min-width: $laptop-min) {
            width: 915px;
            max-width: none;
          }

          .tag-container {
            justify-content: flex-end;
          }
        }

        .external {
          padding-right: 20px;
        }

        img {
          left: auto;
          right: 50%;

          @media screen and (min-width: $about-break) {
            right: 45%;
          }
        }
      }
    }
  }
}
