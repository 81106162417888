@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html,
body,
#root,
.App {
  margin: 0;
}
html {
  background-color: #151515;
}

body {
  background-attachment: fixed;
}

.App {
  text-align: center;
  color: #808080;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.is-animating {
  visibility: hidden;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}
