$accent: #bae038;
$save-purple: #9b5dff;
$main-purple: #7e60e6;
$light-purple: #af9cf0;
$dark-gray: #272a2a;
$medium-gray: #575959;
$light-gray: #808080;
$pale-gray: #c4c4c4;
$bg: #151515;
$bg0: #0b1515;
$bg1: #0e1212;
$bg2: #2a2d5a;

$purple-accent: #8e74e9;
$purple-accent-active: #6351a3;
$green-accent: #adfcd2;
$green-accent-hover: #64c692;

$green-accent3: #7df8b6;
$green-accent2: #adfcd2;
$green-accent1: #c8f4dc;

$accent2: #fffe57; //bright yellow
$accent3: #050505; //pale purple
$accent4: #adfcd2; //pastel green
$accent5: #87e0fe; //sky blue

$tablet-min: 426px;
$about-break: 515px;
$home-break: 615px;
$tablet-max: 768px;
$laptop-min: 1024px;
$desktop: 1440px;
