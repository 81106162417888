@import "./variables.scss";
@import "./fonts.scss";

.projects {
  display: flex;
  flex-direction: column;
  // background-color: #151515;
  background: rgb(21, 21, 21);
  background: linear-gradient(
    180deg,
    rgba(21, 21, 21, 1) 0%,
    rgba(21, 21, 21, 1) 91%,
    rgba(21, 21, 21, 0) 100%
  );
  align-items: center;
  justify-content: center;
  padding: 140px 0px;
  // margin-bottom: 200px;
  // border-bottom: 1px solid red;

  .projects-header {
    width: 300px;
    text-align: left;

    @media screen and (min-width: $tablet-min) {
      width: 80%;
    }

    h4 {
      font-family: "Roboto Mono Medium", serif;
      font-variant: small-caps;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2em;
      align-self: flex-start;
      color: $purple-accent;
      margin: 0px;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      margin: 0px;
    }
  }
  .all-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 100px;

    @media screen and (min-width: $tablet-min) {
      width: 80%;
      margin-top: 2vw;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .project-card {
      width: 295px;
      height: 258px;
      margin-top: 35px;
      background-color: #202022;
      position: relative;
      overflow: hidden;
      cursor: default;

      @media screen and (min-width: $tablet-min) {
        // margin-top: 3vw;
        margin: 1vw;

        // &:last-of-type {
        //   margin: 0 auto;
        //   margin-top: 3vw;
        // }
      }

      &:hover {
        img {
          opacity: 1;
          top: 60%;
        }

        .external,
        .github {
          color: white;
        }
      }

      h5 {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        color: white;
        text-align: left;
        margin: 0px;
        margin-top: 25px;
        margin-left: 25px;
        margin-bottom: 12px;
        position: relative;

        .external,
        .github {
          position: absolute;
          bottom: 0px;
          right: 20px;
          // padding-left: 10px;
          color: $light-gray;
        }

        .github {
          right: 50px;
          // padding-left: 40px;
        }
      }

      p {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        font-size: 17px;
        text-align: left;
        margin: 0px;
        padding: 0px 25px;
      }

      .tag-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 12px;
        margin-left: 25px;

        .tag {
          z-index: 100000;
        }
      }

      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 68%;
        filter: grayscale(30%);
        opacity: 0.7;
        transition: all 0.3s ease;
      }
    }
  }
}
