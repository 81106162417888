@import "./variables.scss";
@import "./fonts.scss";

footer {
  width: 100%;
  margin-bottom: 10px;

  .icons {
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .icon {
      color: white;

      @media (hover: hover) {
        &:hover {
          color: $purple-accent;
        }
      }

      &:active {
        color: $purple-accent-active;
      }
    }
  }

  .disclaimer {
    margin-top: 10px;
    p {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 12px;
      margin: 0 auto;
      // width: auto;
      width: fit-content;
      &:hover {
        color: $purple-accent;
      }
    }
  }
}
